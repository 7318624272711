import React from 'react';

// libraries
import { css } from '@emotion/core';
import { ReactNode } from 'react';

import { Vars } from 'style';

type Row = Array<string | ReactNode>;

interface Props {
  className?: string;
  columns: Row;
  data: Row[];
}

export default function Table(props: Props) {
  const { className, columns, data } = props;

  return (
    <table className={className} css={table}>
      <thead>
        <tr>
          {columns.map((column, i) => (
            <th key={i} css={th}>
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr key={i} css={tr}>
            {row.map((cell, j) => (
              <td key={j} css={td}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const table = css`
  background-color: ${Vars.Shade700};
  border-radius: ${Vars.BorderRadius};
  box-shadow: inset 0 0 0 0.1rem ${Vars.Tint200};
`;

const th = css`
  padding: 0.8rem 1.2rem;
  box-shadow: inset 0.1rem -0.1rem 0 ${Vars.Tint200};
  text-align: left;

  &:first-child {
    box-shadow: inset 0 -0.1rem 0 ${Vars.Tint200};
  }
`;

const tr = css`
  :nth-child(odd) {
    background-color: ${Vars.Tint100};
  }
`;

const td = css`
  padding: 0.8rem 1.2rem;
  box-shadow: inset 0.1rem 0 0 ${Vars.Tint200};

  &:first-child {
    box-shadow: none;
  }
`;
